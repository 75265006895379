<template>
  <div class="create-booking-page">
    <BackTitle confirm-click @click="goBack">
      {{ $t("Create booking") }}
    </BackTitle>
    <BookingForm
      :value="booking"
      :isSubmitting="isLoading"
      :isShowOverbookOption="isShowOverbookOption"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import BookingForm from "@/components/bookings/BookingForm";
import { mapActions, mapState } from "vuex";
import { NEW_BOOKING_MOCK } from "@/helpers/mocks";
import dialog from "@/plugins/dialog";
import { getMinutes, getTimezoneOffsetHours } from "@/helpers/utils";
import moment from "moment";

export default {
  name: "CreateBookingPage",
  components: { BookingForm, BackTitle },
  props: {
    preSelectedSlot: {
      type: Object,
      default: null,
    },
    preSelectedProductId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      booking: {
        ...NEW_BOOKING_MOCK,
        reservationInfo: { ...NEW_BOOKING_MOCK.reservationInfo },
      },
      isPreSelectedSlotHandled: false,
      isShowOverbookOption: false,
    };
  },
  computed: {
    ...mapState({
      slots: (state) => state.bookings.freeSlots,
      venue: (state) => state.venues.selectedVenue,
    }),
  },
  watch: {
    slots(val) {
      if (!val.length) return;

      this.handlePreSelectedSlot();
    },
  },
  async created() {
    const { bookingPaymentStatus: status } = this.$route.query;
    if (status) {
      const isSuccessAdded = status === "success";
      const alertOptions = isSuccessAdded
        ? {
            title: "Payment was successful!",
            okText: "Done",
          }
        : {
            title: "Some problem with payment...",
            okText: "Done",
          };
      setTimeout(async () => {
        await this.$router.push({
          name: "Bookings",
          query: {
            isSuccessAdded,
          },
        });
        await dialog.alert(alertOptions);
      }, 0);
    } else {
      this.booking.date = moment().format("YYYY-MM-DD");
      if (this.preSelectedProductId) {
        this.booking = {
          ...this.booking,
          productId: this.preSelectedProductId,
        };
      }
      if (this.preSelectedSlot) {
        this.booking = {
          ...this.booking,

          productId: this.preSelectedSlot.productId,
          date: this.preSelectedSlot.date,
        };
      }
      this.handlePreSelectedSlot();
    }
  },
  methods: {
    ...mapActions({
      createBooking: "bookings/createBooking",
      checkout: "bookings/checkout",
      addReservationInfo: "bookings/addReservationInfo",
    }),
    handlePreSelectedSlot() {
      if (!this.isPreSelectedSlotHandled && this.preSelectedSlot) {
        const offset = getTimezoneOffsetHours(this.venue.timezone);
        const slot = this.slots?.find(
          (slot) =>
            getMinutes(slot.from) + offset * 60 ===
              +this.preSelectedSlot.start &&
            getMinutes(slot.to) + offset * 60 === +this.preSelectedSlot.end
        );

        this.booking = {
          ...this.booking,
          slots: slot ? [slot] : [],
        };
        this.isPreSelectedSlotHandled = !!slot;
      }
    },
    async handleSubmit(data, _, isSkipPayment) {
      try {
        this.isLoading = true;
        const {
          date,
          slots,
          reservationInfo,
          playersCount,
          productId,
          customPrice,
          upsellItems,
          isWalkIn,
          isConfirmationEmail,
          isSpecifyGroupSize,
          isDeposit,
          withoutPaymentOption,
          selectedGroupId,
          selectedGroup,
          isFullFirstPayment,
          affiliateId,
          isAllowOverbook,
        } = data;

        const booking = await this.createBooking({
          chosenDate: date,
          chosenSlots: slots,
          playersCount,
          productId,
          isWalkIn,
          isSpecifyGroupSize,
          isConfirmationEmail,
          affiliateId,
          ...(selectedGroupId && selectedGroup
            ? { selectedGroupId, selectedGroup }
            : {}),
          isAllowOverbook,
        });
        await this.addReservationInfo({
          bookingId: booking.id,
          reservationInfo,
        });

        await this.checkout({
          bookingId: booking.id,
          data: {
            isSkipPayment,
            isDeposit,
            customPrice,
            upsellItems,
            withoutPaymentOption,
            selectedGroupId,
            isFullFirstPayment,
          },
        });
        if (isSkipPayment || withoutPaymentOption) {
          await this.goBack();
        } else {
          await this.$router.push({
            name: "BookingCheckout",
            params: { id: booking.id },
          });
        }
      } catch (err) {
        if (err?.response?.data?.code === "not-enough-resources") {
          this.isShowOverbookOption = true;
        }
      } finally {
        this.isLoading = false;
      }
    },
    async goBack() {
      await this.$router.push({
        name: "Bookings",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.create-booking-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
}
</style>
